import { useAuthStore } from "~/store/auth";

export default defineNuxtRouteMiddleware((to) => {
  const authStore = useAuthStore();
  const { $toast } = useNuxtApp();

  if (process.client) {
    if (!authStore.company?.has_valid_clearance) {
      $toast.error("Habilitation non valide.");
      return navigateTo("/dashboard");
    }

    if (authStore.company?.clearance_need_verification) {
      $toast.error("Habilitation en attente de vérification.");
      return navigateTo("/dashboard");
    }
  }
});
